ion-content ion-toolbar {
  --background: translucent;
}

body {
  color: #333;
  font-size: 18px;
  line-height: 24px;
}

.background-span {
  background-color: #f4f4f4;  /* css js */
  border: none;
  color: white;
  font-size: 12pt;  /* css js */
  font-weight: bold;
  line-height: 48px;  /* css js */
  overflow: hidden;
  padding-left: 18px;
  text-align: left;
}

.button-back, .button-next {
  background-color: transparent;
  color: var(--ion-color-light-contrast);
  height: 100%;
  position: absolute;
  top: 0px;
}
.button-back {
  left: 0px;
}

.button-next {
  right: 0px;
}

strong {
  color: #C70039;
  font-style: normal !important; 
}

p img {
  width: 100%;
  max-width: 500px;
}

ion-button {
  margin: 0;
}