/**
* ----------------------------------------------
* Demo styles
* ----------------------------------------------
**/
.accordion {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2px;
}

.prayer-accordion-item {
    border-bottom: 1px solid var(--ion-color-light-shade);
}

.accordion-button {
    cursor: pointer;
    font-weight: bold;
    outline: none;
    overflow: hidden;
    padding-left: 20px;
    width: 100%;
}

.prayer-button {
    color: #727272;
    font-size: 18px;
    height: 48px;
    line-height: 48px;
}

.accordion__button:hover {
    background-color: #ddd;
}

.accordion__panel {
    animation: fadein 0.35s ease-in;
    padding: 10px; /* complementary with .slide padding */
    position: relative; /* to allow the back/next buttons to position in the margin */
}

.slide {
    padding: 10px; /* complementary with .accordion__panel padding */
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
